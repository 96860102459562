import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { uploadFile } from "@data/firebase/storage";
import "./UploadFile.scss";

const UploadFile = forwardRef(({ filepath, onUploadComplete, fileType = "image" }, ref) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const acceptTypes = fileType === "image" ? ".jpg,.jpeg,.png" : ".mp3,.wav";
  const allowedTypes = fileType === "image" ? ["image/jpeg", "image/png"] : ["audio/mpeg", "audio/wav"];

  // File size limits: 5MB for images, 20MB for audio
  const maxFileSize = fileType === "image" ? 2 * 1024 * 1024 : 20 * 1024 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        alert(`Please select a valid ${fileType} file.`);
        return;
      }
      if (file.size > maxFileSize) {
        const maxSizeMB = fileType === "image" ? 2 : 20; // Set alert size dynamically
        alert(`Oops, this file size exceeds ${maxSizeMB}MB. Please upload a smaller ${fileType} file.`);
        return;
      }
      setIsUploading(true);
      uploadFile(
        file,
        filepath,
        (progress) => {
          setProgress(progress);
        },
        (fileInfo) => {
          setIsUploading(false);
          onUploadComplete(fileInfo);
        },
        (error) => {
          setIsUploading(false);
          console.error("Upload failed", error);
        }
      );
    }
  };

  useImperativeHandle(ref, () => ({
    resetFileInput: () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input value
      }
    }
  }));

  return (
    <div className="upload-file-container">
      <input ref={fileInputRef} type="file" accept={acceptTypes} onChange={handleFileChange} />
      <p className="upload-instructions">
        {fileType === "image" ? (
          <span>
            <strong>Image Upload:</strong> Please select an image file (Max size: <strong>2MB</strong>, formats: JPG, PNG).
          </span>
        ) : (
          <span>
            <strong>Audio Upload:</strong> Please select an audio file (Max size: <strong>20MB</strong>, formats: MP3, WAV).
          </span>
        )}
      </p>
      {isUploading && (
        <div className="upload-progress">
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
});

export default UploadFile;