import React from 'react';
import './PlanComparison.scss';

const PlanComparison = () => {
  const features = [
    { feature: 'Upload your own drills', free: false, paid: true },
    { feature: 'Create your own drills', free: false, paid: true },
    { feature: 'Set your goals', free: false, paid: true },
    { feature: 'Record your progress', free: false, paid: true },
    { feature: 'Set loops for practice', free: false, paid: true },
    { feature: 'Adjust speed & pitch', free: false, paid: true },
  ];

  return (
    <div className="plan-comparison">
      <h2>Compare Plans</h2>
      <h3>See what you'll get with each plan and choose the one that fits your needs!</h3>
      <div className="comparison-table">
        <div className="table-header">
          <div className="cell">Features</div>
          <div className="cell">Free</div>
          <div className="cell">Paid</div>
        </div>
        {features.map((row, index) => (
          <div className="table-row" key={index}>
            <div className="cell">{row.feature}</div>
            <div className="cell">{row.free ? '✅' : '❌'}</div>
            <div className="cell">{row.paid ? '✅' : '❌'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanComparison;