export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export const formatTime = (timestamp) => {
    // Convert Firebase Timestamp to milliseconds
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

    // Create a new Date object using milliseconds
    const date = new Date(milliseconds);

    // Extract hours, minutes, and seconds from the Date object
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    // Format the time as "hh:mm:ss"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
}

export const isValidString = (value) => {
  return typeof value === 'string' && value.trim().length > 0;
};

export const formatTimeMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Pad the minutes and seconds with a leading zero if necessary
  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const paddedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  return `${paddedMinutes}:${paddedSeconds}`;
}

export const checkDrillData = (data) => {
  if (data.drill === '' || data.bpm === '' || data.category === '') {
    return false;
  } else if (data.bpm < 10 || data.bpm > 300) {
    return false;
  } else {
    if(data.type == 'audio'){
      if(data.file === '') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

export const formatDate = (timestamp) => {
  // Convert Firebase Timestamp to milliseconds
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

  // Create a new Date object using milliseconds
  const date = new Date(milliseconds);

  // Extract day, abbreviated month, and year from the Date object
  const day = date.getUTCDate().toString().padStart(2, '0'); // Day of the month with leading zero
  const month = date.toLocaleString('en-US', { month: 'short' }); // Abbreviated month name
  const year = date.getUTCFullYear(); // Full year

  // Return JSX with formatted date
  return (  
    <span className="date-dm">{day} {month} {year}</span>
  );
};

export const formatDateTime = (timestamp, html = true) => {
  // Convert Firebase Timestamp to milliseconds
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

  // Create a new Date object using milliseconds
  const date = new Date(milliseconds);

  // Get the user's local date and time components
  const day = date.getDate().toString().padStart(2, '0'); // Day with leading zero
  const monthAbbreviations = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = monthAbbreviations[date.getMonth()]; // Abbreviated month name
  const year = date.getFullYear().toString().slice(-2); // Last two digits of the year

  // Extract hours and minutes in the user's timezone
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Format: 13-Jan-25 18:04
  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  if (html) {
    return (
      <>
        <span className="date-dm">{day}-{month}-{year}</span>{' '}
        <span className="date-time">{hours}:{minutes}</span>
      </>
    );
  } else {
    return formattedDateTime;
  }
};



export const checkPassword = (str) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export const shuffleArray = (array) => {
  let i = array.length;
  while (i > 0) {
    const ri = Math.floor(Math.random() * i);
    i--;
    [array[i], array[ri]] = [array[ri], array[i]];
  }
  return array;
}

export const groupByKey = (array, key) => {
  // Create an object to store groups
  const grouped = {};

  // Iterate over each object in the array
  array.forEach((item) => {
    // Get the value for the specified key
    const keyValue = item[key];

    // Check if the keyValue already exists in the grouped object
    if (!grouped[keyValue]) {
      // If not, create a new array for this key
      grouped[keyValue] = [];
    }

    // Add the current item to the group for this key
    grouped[keyValue].push(item);
  });

  // Convert the grouped object values to an array of arrays
  return Object.values(grouped);
};

export const sortedAndFilteredDrills = (drills, searchDrill, sortConfigDrill) => {
  return drills
    .filter((val) =>
      isValidString(val.drill) &&
      (!searchDrill || val.drill.toLowerCase().includes(searchDrill.toLowerCase())) // Allow empty search
    )
    .sort((a, b) => {
      if (sortConfigDrill && sortConfigDrill.key) {
        if (a[sortConfigDrill.key] < b[sortConfigDrill.key]) {
          return sortConfigDrill.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfigDrill.key] > b[sortConfigDrill.key]) {
          return sortConfigDrill.direction === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
};

export const handleSortDrill = (key, sortConfigDrill, setSortConfigDrill) => {
  if (!sortConfigDrill) {
    sortConfigDrill = { key: null, direction: 'asc' };
  }
  
  let direction = 'asc';
  if (sortConfigDrill.key === key && sortConfigDrill.direction === 'asc') {
    direction = 'desc';
  }
  setSortConfigDrill({ key, direction });
};

export const formatMinutesFromSeconds = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);  // Convert seconds to minutes
  return totalMinutes;  // Return the total minutes
};


export const getUserRole = (user) => {
  return (user && user.role) ? user.role : 'USER';
}
