import React, {useEffect, useState, createContext} from "react";

import "./Popup.scss";

export const PopupContext = createContext(false);

function Popup({ children, title, onChange, closePopup, active, cancel = true, savePopup = true, saveTitle = "Save" }) {

  const [popupActive, setPopupActive] = useState(active);

  useEffect(() => {
    setPopupActive(active);
  }, [active]);

  const handleClose = (e) => {
    closePopup(e, false);
  };

  return (
    <div className={'main-popup' + (active ? ' active' : '')}>
      <div className="bg-close" onClick={(e) => handleClose(e)} />
      <div className="popup-outer">
        <div className="btn-close" onClick={(e) => handleClose(e)} />
        <h2>{title}</h2>
        <div className="popup-inner">
          <PopupContext.Provider value={{ popupActive, setPopupActive }}>
            {children}
          </PopupContext.Provider>
        </div>
        <>
          {(cancel || savePopup) && (
            <div className="popup-actions">
              <>
                {savePopup && (
                  <div className="btn-main small btn-save" onClick={(e) => onChange(e, closePopup)}>
                    {saveTitle}
                  </div>
                )}
              </>
              <>
                {cancel && <div className="btn-main small btn-cancel" onClick={(e) => handleClose(e)}>Cancel</div>}
              </>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Popup;