import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Drill from "@components/Drills";
import { Slider, ThemeProvider, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import UiTheme from '@data/themes';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import { formatTimeMinutes, formatDateTime } from '@data/helpers';
import Loading from "@components/Loading";
import { createDocument } from '@data/firebase/firestore/saveData';
import Popup from "@components/Popup";
import { v4 as uuidv4 } from 'uuid';
import TextInput from "@components/Inputs/TextInput";
import Gallery from '@components/Gallery';
import Tickbox from '@components/Tickbox';
import { getCollectionQueryBetween } from '@data/firebase/firestore/getData';
import { Timestamp } from "firebase/firestore";
import MetroWave from "./components/MetroWave";
import History from "./components/History";
import { startSequence, stopSequence } from './soundFiles';
import { PopupContext } from '@components/Popup';

const Practice = ({ children, startbpm = 80, startname = '', startspeed = 1 }) => {
  const [value, setValue] = useState(80);
  const [isTimerPlaying, setIsTimerPlaying] = useState(false);
  const [isMetronomePlaying, setIsMetronomePlaying] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [drillName, setDrillName] = useState('');
  const [drillImage, setDrillImage] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [note, setNote] = useState(''); // State for the note
  const audioContextRef = useRef(null);

  const metronomeTimerRef = useRef(null);
  const timerRef = useRef(null);
  const startTimeRef = useRef(null);
  const elapsedTimeRef = useRef(0);
  const [timeText, setTimeText] = useState('00:00');
  const [signature, setSignature] = useState('quarter');
  const [drum, setDrum] = useState('bell');
  const clickCountRef = useRef(0);
  const [drills, setDrills] = useState([]);
  const user_store = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [drillHistory, setDrillHistory] = useState([]);
  const [popupHistory, setPopupHistory] = useState(false);
  const [isNewDrill, setIsNewDrill] = useState(false);
  const [categories, setCategories] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedInstrument, setSelectedInstrument] = useState('All');
  const location = useLocation();
  const [autostartTimer, setAutostartTimer] = useState(false);
  const [drillPicOverlay, setDrillPicOverlay] = useState(false);
  const [goal, setGoal] = useState(user_store.goal ? (user_store.goal / 60) : 0);
  const [done, setDone] = useState(0);
  const [congrats, setCongrats] = useState(false);
  const [drillType, setDrillType] = useState('metronome'); 
  const [drillAudio, setDrillAudio] = useState(false);
  const [extraSave, setExtraSave] = useState({});

  const [drillStart, setDrillStart] = useState(0);
  const [drillEnd, setDrillEnd] = useState(3);
  const [drillSpeed, setDrillSpeed] = useState(startspeed);

  const popupActive = useContext(PopupContext);

  const checkDone = () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // Start of the day
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // End of the day

    const startOfDay = Timestamp.fromDate(todayStart);
    const endOfDay = Timestamp.fromDate(todayEnd);
    getCollectionQueryBetween(
      'users/' + user_store.email + '/logs', 
      (response) => {
        const totalSeconds = response.reduce((accumulator, currentLog) => {
          return accumulator + (currentLog.seconds || 0); // Assuming seconds field is present in each log
        }, 0);
        const totalMinutes = Math.floor(totalSeconds / 60);
        setDone(totalMinutes);
        if(goal && totalMinutes >= goal){
          setCongrats(true);
        }
      },
      {
        field: 'datetime', 
        value: { start: startOfDay, end: endOfDay }
      }
    );
  }

  useEffect(() => {
    checkDone();
  }, [])

  useEffect(() => {
    handleReset();
  }, [popupActive, drillName]);

  useEffect(() => {
    setValue(startbpm);
    setDrillName(startname);
    setDrillSpeed(startspeed);
    if(startname !== ''){
      fetchDrillHistory(startname);
      fetchDrillByName(startname);
    }
    if (location.state) {
      const { drillName, bpm } = location.state;
      console.log({ drillName, bpm });
      setDrillName(drillName);
      setValue(bpm);
    }
    getCollection('drills', (main_drills) => {
      getCollection('users/' + user_store.email + '/drills', (user_drills) => {
        let all_drills = main_drills.concat(user_drills);
        setDrills(all_drills);

        const uniqueCategories = [...new Set(all_drills.map(drill => drill.category).filter(Boolean))];
        const uniqueInstruments = [...new Set(all_drills.map(drill => drill.instrument).filter(Boolean))]; // Extract unique instruments

        let cat_list = uniqueCategories.map(category => ({
          id: uuidv4(),
          label: category
        }));
        let instrument_list = uniqueInstruments.map(instrument => ({
          id: uuidv4(),
          label: instrument
        }));

        setCategories([{ label: 'All' }, ...cat_list]);
        setInstruments([{ label: 'All' }, ...instrument_list]);
      });
    });

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
  }, [location.state, user_store.email, startbpm, startname, startspeed]);

  useEffect(() => {
    if (isMetronomePlaying) {
      startSequence(drum, signature, value)
      if(autostartTimer){
        setIsTimerPlaying(true);
      }
    } else {
      stopSequence();
      if(autostartTimer){
        setIsTimerPlaying(false);
      }
    }
    return () => {
      stopSequence();
    };
  }, [isMetronomePlaying, value, signature, drum]);

  useEffect(() => {
    if (isTimerPlaying) {
      startTimer();
    } else {
      stopTimer();
    }
    return () => {
      stopTimer();
    };
  }, [isTimerPlaying]);


  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
    }
  };

  const handleDrillNameChange = (event) => {
    setDrillName(event.target.value);
  };

  const stopMetronome = () => {
    if (metronomeTimerRef.current) {
      clearInterval(metronomeTimerRef.current);
      if(autostartTimer){
        setIsTimerPlaying(false);
      }
    }
  };

  const startTimer = () => {
    setHasStarted(true);
    startTimeRef.current = Date.now() - elapsedTimeRef.current;
    timerRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTimeRef.current;
      elapsedTimeRef.current = elapsedTime;
      const seconds = Math.floor(elapsedTime / 1000);
      const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
      const remainingSeconds = String(seconds % 60).padStart(2, '0');
      setTimeText(`${minutes}:${remainingSeconds}`);
      setTimerSeconds(seconds);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const handleReset = () => {
    stopTimer();
    setIsMetronomePlaying(false)
    setIsTimerPlaying(false);
    setHasStarted(false);
    elapsedTimeRef.current = 0;
    setTimeText('00:00');
    setTimerSeconds(0);
  };

  const finishSaveLog = () => {
    setLoading('Log saved');
    setTimeout(() => {
      setShowLog(true);
      setLoading(false);
      setTimeout(() => {
        setShowLog(false);
        handleReset();
      }, 6000); 
    }, 500);
  }

  const saveLog = () => {
    const currentDate = new Date();
    setLoading('Saving log...');
    let save_data = {
      ...extraSave,
      goal: parseInt(user_store.goal),
      drill: drillName,
      seconds: timerSeconds,
      bpm: value,
      signature: signature,
      drums: drum,
      datetime: currentDate,
      note: note // Save the note
    }
    createDocument('users/' + user_store.email + '/logs', save_data, (response) => {
      setIsMetronomePlaying(false);
      setIsTimerPlaying(false);
      checkDone();
      if (isNewDrill) {
        setLoading('Creating new drill...');
        const currentDate = new Date();
        createDocument('users/' + user_store.email + '/drills', {
          drill: drillName, bpm: parseInt(value), user: user_store.email, date: currentDate, file: false
        }, (response) => {
          finishSaveLog();
        });
      } else {
        finishSaveLog();
      }
    });
  }

  const fetchDrillHistory = (name) => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      // console.log('Fetched Drill History:', response)
      setDrillHistory(response);
    }, { field: 'drill', value: name }, { field: 'datetime', order: 'DESC' });
  }

  const fetchDrillByName = (name) => {
    setDrillImage(false);
    getCollectionQuery('drills', (response) => {
      if(response.length){
        setDrillImage(response[0].file)
        setDrillType('metronome');
        if(response[0].type && response[0].type === 'audio'){
          setDrillAudio(response[0].audio);
          setDrillType('audio');
          setDrillStart(response[0].start);
          setDrillEnd(response[0].end);
        } else {
          setDrillImage(response[0].file)
        }
      } else {
        getCollectionQuery('users/' + user_store.email + '/drills', (user_drills) => {
          if(user_drills.length){
            setDrillType('metronome');
            if(user_drills[0].type && user_drills[0].type === 'audio'){
              setDrillAudio(user_drills[0].audio);
              setDrillType('audio');
              setDrillStart(user_drills[0].start);
              setDrillEnd(user_drills[0].end);
            } else {
              setDrillImage(user_drills[0].file)
            }
          } 
        }, { field: 'drill', value: name });
      }
    }, { field: 'drill', value: name });
  }

  const filteredDrills = drills.filter(drill =>
    (selectedCategory === 'All' || drill.category === selectedCategory) &&
    (selectedInstrument === 'All' || drill.instrument === selectedInstrument) // Filter by instrument
  );

  return (
    loading ? <Loading loading={loading} /> :
      <ThemeProvider theme={UiTheme}>
        { drillPicOverlay && <Gallery picture={ drillPicOverlay }  open={ drillPicOverlay } setOpen={ setDrillPicOverlay } /> }
        <div id="practice">
          <Popup title="History" savePopup={false} cancel={false} active={popupHistory} closePopup={() => { setPopupHistory(false); }}>
            <History history={drillHistory} />
          </Popup>
          <div className="timer-wrapper">
            <div className="timer">
              <div className="timer-box">
                <h3>Timer</h3>
                <div className="timer-text">
                  <button className={isTimerPlaying ? 'btn-pause' : 'btn-play'} onClick={() => setIsTimerPlaying(!isTimerPlaying)}>
                    {isTimerPlaying ? 'Stop' : 'Start'}
                  </button>
                  <h1>{timeText}</h1>
                  {hasStarted && !isTimerPlaying && (
                    <button className="btn-reset" onClick={handleReset}>Reset</button>
                  )}
                </div>
                {user_store.goal && <span className="info-goal">Daily Practice Goal (minutes) {formatTimeMinutes(user_store.goal)}</span>}
                { (!congrats && done) ? <span className="info-congrats">Nice! You played { done } of the { goal } minutes already.</span> : <></> }
                { congrats && <span className="info-congrats">You met your goal! Keep going! 🤩</span> }
                <div className="log-search">
                  <div style={{ maxWidth: '100px', flex: '0 0 100px' }}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} label="Category">
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.label}>
                            {category.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ maxWidth: '100px', flex: '0 0 100px' }}>
                    <FormControl fullWidth>
                      <InputLabel>Instrument</InputLabel>
                      <Select value={selectedInstrument} onChange={(e) => setSelectedInstrument(e.target.value)} label="Instrument">
                        {instruments.map((instr) => (
                          <MenuItem key={instr.id} value={instr.label}>
                            {instr.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Autocomplete freeSolo id="drill-search" options={filteredDrills} getOptionLabel={(option) => option.drill || ''} renderInput={(params) => (
                      <TextField {...params} label="Drill" variant="outlined" placeholder="Search or create drill" InputLabelProps={{ shrink: true }}/>
                    )}
                    onChange={(event, newValue) => {
                      let drill_name = typeof newValue === 'string' ? newValue : newValue?.drill || '';
                      setDrillName(drill_name);
                      fetchDrillHistory(drill_name);
                      let new_bpm = newValue?.bpm || 120;
                      setIsNewDrill(!newValue?.bpm);
                      setValue(parseInt(new_bpm));
                      setDrillType('metronome');
                      console.log(newValue);
                      if(newValue?.type && newValue?.type === 'audio'){
                        setDrillAudio(newValue?.audio);
                        setDrillType('audio');
                        setDrillStart(newValue?.start);
                        setDrillEnd(newValue?.end);
                      } else {
                        setDrillImage(newValue?.file)
                      }
                      setDrillImage(newValue?.file);
                    }}
                    value={filteredDrills.find(drill => drill.drill === drillName) || null}
                  />
                </div>
              </div>
              
              <div className="metronome-box">
                <h3>{(drillName === '') ? '' : drillName} {(drillHistory.length) ? <div onClick={() => setPopupHistory(true)} className="btn-history"></div> : <></>}</h3>
                {(drillHistory.length) ? (
                  <div className="info-drill">
                    <div className="info-item highlight">
                      <span className="title">Date:</span>
                      <span className="value">{formatDateTime(drillHistory[0].datetime, false)}</span>
                    </div>
                    {(drillType !== 'audio') ? 
                    <div className="info-item">
                      <span className="title">Last BPM:</span>
                      <span className="value">{drillHistory[0].bpm}</span>
                    </div>: <></>}
                    <div className="info-item">
                      <span className="title">Time Practiced:</span>
                      <span className="value">{formatTimeMinutes(drillHistory[0].seconds)}</span>
                    </div>
                    <div className="info-item">
                      <span className="title">Speed:</span>
                      <span className="value">
                        {drillHistory[0]?.speed && !isNaN(drillHistory[0].speed)
                          ? `${(drillHistory[0].speed * 100).toFixed(0)}%`
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                { (drillType === 'metronome') && (
                  <>
                    {(isNewDrill) ? <div className="info-drill">Creating a new drill.</div> : <></>}
                    <div className="drill">
                      {drillImage && (<div className="drill-wrapper">
                        <img onClick={ () => setDrillPicOverlay(drillImage) } src={drillImage} alt="Logo" />
                      </div>)}
                    </div>
                    <div className="timer-slider">
                      <Slider aria-label="Volume" valueLabelDisplay="auto" value={value} onChange={handleSliderChange} min={10} max={300} />
                      <input className="in-value" type="number" value={value} onChange={handleInputChange} />
                    </div>
                    <div className="select-drill-wrapper">
                      <Box className="drill-options">
                        <FormControl fullWidth>
                          <InputLabel id="select-drill-label">Drill</InputLabel>
                          <Select className="select-drill" value={signature} label="Drill" onChange={(event) => setSignature(event.target.value)}>
                            <MenuItem value={'quarter'}>Quarter Notes</MenuItem>
                            <MenuItem value={'triplet'}>Triplets</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="select-drill-label">Drums</InputLabel>
                          <Select className="select-drum" value={drum} label="Drum" onChange={(event) => setDrum(event.target.value)}>
                            <MenuItem value={'bell'}>Bell Drums</MenuItem>
                            <MenuItem value={'snare'}>Snare Drum</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <Tickbox checked={autostartTimer} onChange={e => setAutostartTimer(!autostartTimer) } label={ (isMetronomePlaying?'Stop':'Start')+" timer with metronome."} />
                    <div className={isMetronomePlaying ? 'btn-main full-width big stop' : 'btn-main full-width big play'} onClick={() => setIsMetronomePlaying(!isMetronomePlaying)}>
                      <i /><span>{isMetronomePlaying ? 'Stop' : 'Start'}</span>
                    </div>
                  </>
                )}
                {(drillType === 'audio') && (<MetroWave audioFile={drillAudio} setIsTimerPlaying={setIsTimerPlaying} setExtraSave={setExtraSave} speed={drillSpeed} start={drillStart} stop={drillEnd} />)}
              </div>
              
              {(timerSeconds > 0 && (drillName !== '')) && (
                <div className="box-savelog">
                  <TextInput label="Add Note" type="text" margin="normal" value={note} onChange={(e) => setNote(e)} placeholder="Add a note to your log"/>
                  <div className="btn-main small btn-savelog" onClick={() => saveLog()}>Save Log</div>
                </div>
              )}
            </div>
          </div>
          {showLog && <div className="box-wrapper">
            <div className="box info">
              <div className="box-header">
                <span className="title">New Log Created</span>
                <a href="/history" className="action a-link">View Full Log</a>
              </div>
              <div className="box-content">
                <span className="ico-info">Awesome! You practiced {drillName} for {Math.round(timerSeconds/60)} minutes!</span>
              </div>
            </div>
          </div>}
        </div>
      </ThemeProvider>
  );
};

export default Practice;
